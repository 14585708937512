<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
      <a-form-model-item label="服务商名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <!-- <a-form-model-item>
        <a-textarea
          v-model="form.remark"
          placeholder="服务商备注"
          :auto-size="{ minRows: 15, maxRows: 20 }"
          size="large"
        />
      </a-form-model-item> -->
      <a-form-item class="group-btn">
        <a-button type="primary" @click="createServiceProvider">确定</a-button>
        <a-button class="creator-btn" @click="cancel">取消</a-button>
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'ServiceProviderCreator',
  components: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {
        name: [{ required: true, message: '服务商名称 不能为空', trigger: 'blur' }],
      },
      value: '',
      form: {
        name: null,
        // remark: null,
        type: 0,
      },
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    createServiceProvider() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.serviceProvider.createServiceProviders(this.form).then(() => {
            this.$message.success('创建成功');
            this.$router.back();
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-btn {
  color: green;
}
.delete-btn {
  color: red;
}
.creator-btn {
  margin-left: 5%;
}
.service-provicer-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .service-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 16.7%;
}
</style>
